import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { ArrowInclined } from "../atoms/Icons"
import { VideoDana } from "../atoms/video"

const MeetDanaPage = () => {
  return (
    <>
      {/* Mobile */}
      <div className="lg:hidden">
        <Layout>
          <h1 className="mobile-header lg:desktop-header">Meet Dana</h1>
          <p className="mobile-body lg:dektop-body pt-4">
            Dana Kopfer Maxey was born in Germany and raised in the San
            Francisco Bay Area, as an experimental by product of 1960’s
            Radicalism, Artistic Expression and Spiritual Freedom. Her parents
            were Mods! She declared herself a Yogini at 6 and a Witch at 9.{" "}
            <br />
            She attended UCLA and UCSB where she studied Theatre. She continued
            her education in Europe, where she studied Mime in Paris, and
            Theatre in Berlin at Akademie der Kunst. Dana spent years working
            and playing in all aspects of The Arts. She has worked on stage as a
            musician, vocalist, actress, and director. She has birthed several
            plays and Screenplays and has a group called{" "}
            <strong>THE VENUS PROJECTS</strong>
            , which forms and performs original works, when friends are
            available to collaborate.
            <br />
            She also plays guitar and harmonium, with her husband, in their
            band,
            <strong> Frida’s Eyebrow.</strong>
            <br />
            <br />
            Her oh so fine cartoony art came out of a lull in the theater and
            encouragement from Bob. She loves to draw and make works on paper.
            One can see the influence of Pablo Picasso, Francoise Gilot and
            Henri Matisse in her work.
          </p>
          <br />
          <p>
            Her mantra:
            <span className="inline mobile-body-italic">
              When the moon is full, it’s high tide in your brain.“
            </span>
            <br /> She is a Stone’s girl.
          </p>
          <br />
          <div className="py-2 max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden  pl-1 flex gap-4 items-center">
            <StaticImage
              height={275}
              src="../images/meetDana/1d.png"
              alt="Dana"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetDana/2d.png"
              alt="Dana Mask"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetDana/3.png"
              alt="Dana Sailing"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetDana/4.png"
              alt="Dana surf"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetDana/5.png"
              alt="Dana show"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetDana/6.png"
              alt="Dana"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
          <Link
            className="py-2 flex items-center font-bold my-4"
            to="/dana-gallery"
          >
            <ArrowInclined />
            Visit Dana's Art Gallery
          </Link>
        </Layout>
      </div>

      {/* Desktop */}
      <div className="hidden lg:block">
        <Layout>
          <h1 className="mobile-header lg:desktop-header">Meet Dana</h1>
          <div className="flex">
            <div className="max-w-md desktop-body">
              <p className="">
                Dana Kopfer Maxey was born in Germany and raised in the San
                Francisco Bay Area, as an experimental by product of 1960’s
                Radicalism, Artistic Expression and Spiritual Freedom. Her
                parents were Mods! She declared herself a Yogini at 6 and a
                Witch at 9. <br />
                She attended UCLA and UCSB where she studied Theatre. She
                continued her education in Europe, where she studied Mime in
                Paris, and Theatre in Berlin at Akademie der Kunst. Dana spent
                years working and playing in all aspects of The Arts. She has
                worked on stage as a musician, vocalist, actress, and director.
                She has birthed several plays and Screenplays and has a group
                called <strong>THE VENUS PROJECTS</strong>
                , which forms and performs original works, when friends are
                available to collaborate.
                <br />
                She also plays guitar and harmonium, with her husband, in their
                band,
                <strong> Frida’s Eyebrow.</strong>
                <br />
                <br />
                Her oh so fine cartoony art came out of a lull in the theater
                and encouragement from Bob. She loves to draw and make works on
                paper. One can see the influence of Pablo Picasso, Francoise
                Gilot and Henri Matisse in her work.
              </p>
              <br />
              <p>
                Her mantra:
                <span className="inline mobile-body-italic">
                  When the moon is full, it’s high tide in your brain.“
                </span>
                <br /> She is a Stone’s girl.
              </p>
              <Link
                className="py-2 flex items-center font-bold my-4"
                to="/dana-gallery"
              >
                <ArrowInclined />
                Visit Dana's Art Gallery
              </Link>
            </div>
            <div className="w-3/4 max-w-screen  flex gap-2 xl:gap-4 2xl:gap-8 flex-col ml-2 2xl:mx-0 pl-8">
              <div className="flex gap-2 xl:gap-4 2xl:gap-8">
                <StaticImage
                  height={558}
                  width={386}
                  src="../images/meetDana/1d.png"
                  alt="Dana art "
                  placeholder="tracedSVG"
                  className="flex-shrink-0"
                />
                <div className="flex-col ">
                  <div className="overflow-x-scroll overscroll-x-contain pl-1 flex gap-2 xl:gap-4 2xl:gap-8 items-center">
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetDana/2d.png"
                      alt="Dana Sailing"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetDana/3.png"
                      alt="Dana Mask"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetDana/4.png"
                      alt="Dana portrait"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetDana/5.png"
                      alt="Dana surfboard"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetDana/6.png"
                      alt="Dana art show"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                  </div>
                  <p className="desktop-quote text-center pt-4">
                    “I am a woman, I can be as contrary as I choose”
                  </p>
                  <br />
                  <p className="desktop-body text-center">Dame Maggie Smith</p>
                </div>
              </div>
              <VideoDana />
              <div className="flex gap-2 xl:gap-4 overflow-x-scroll overscroll-x-contain ">
                <StaticImage
                  height={311}
                  width={480}
                  src="../images/meetDana/7.png"
                  alt="Dana Tibet"
                  placeholder="tracedSVG"
                  className="flex-shrink-0"
                />
                <StaticImage
                  height={411}
                  src="../images/meetDana/8.png"
                  alt="Dana Barra"
                  placeholder="tracedSVG"
                  className="flex-shrink-0 ml-auto"
                />
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </>
  )
}

export default MeetDanaPage
